<template>
    <v-main>
        <Header></Header>
        <div class="section9">
         <v-container class="justify-center">
        

        <v-card-title class="justify-center"   style="margin:5em 0px 5em 0px; word-break: normal;"  primary-title>
             <h2>
                Karmakol Festival
             </h2>
         </v-card-title>
        </v-container>
             <v-container >
                 <div class="space" >

            <v-card  hover outlined raised color="rgba(139, 214, 255,0.2)">
                <v-row>
                  <v-col>
                      <v-card-title class="justify-center" >    
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/location.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center">
                      Khartoum, Sudan
                      </v-card-title>
                          
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                        
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/Category.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center" >
                          
                          UI/UX Design, Mobile Development, Web Development, BackEnd Development, Cloud Architecture, and Software Consultancy
                      </v-card-title>
                     
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                         <v-img height="3em" width="4em" contain :src="require('../assets/icons/industry.svg')"></v-img>
                      </v-card-title>
                         <v-card-title style="word-break:normal" class="justify-center">
                           Events/Festivals
                         </v-card-title>
                  </v-col>
                </v-row>
            </v-card>
                 </div>
              </v-container>
            <!-- </v-row> -->
            
        <v-container>
        <v-container>
        <v-row>
            <v-col cols="12" xl="3" lg="3" md="4" sm="12"   >
                 <v-container >
                <div class="space" >
                    <v-img contain max-height="15em" max-width="15em" :src="require('../assets/portfolio/Karmakol 04_Artboard 3.png')">
                    
                    </v-img>
                </div>
                  </v-container>
            </v-col>

                





            <v-col>
                <v-container>    
                <v-row>
                <h2>About Karmakol Festival</h2> 
                <div class="space">
                    <v-col>
                    <p>
                        Karmakol Festival: Nile Edition is a festival
                        that brings together highlights from the
                        Sudanese and International cultural scene to
                        workshop, exchange and perform with the
                        aim to CONNECT and CREATE. 
                    </p>

                    </v-col>
                </div>
                </v-row>
                </v-container>
            </v-col>
        </v-row>
        </v-container>
         <v-container>
                    
        <v-row>
            <v-col >
                <h2>
                    What The Code Effect Did:
                </h2>
                <div class="space">
                    <v-container>
                        

                <p>
                    Karmakol Festival project is a collection of
                    three cross-platform mobile apps and a
                    web-based admin panel to provide technical
                    and logistical support to the organizors of
                    the festival which was supposed to take
                    place in December 2021. It's been delayed
                    due to the coup in Sudan. The project
                    introduces a fast, secure, and convenient
                    payment method by transforming the
                    festival into a cashless payment society.
                    
                </p>
                    </v-container>
    </div>
            </v-col>
        </v-row>
         <v-container >
            <v-row>
          
              <v-col >
                  <iOsMockup></iOsMockup>
              </v-col>
            </v-row>
    </v-container>

          </v-container>
           
            <!-- <v-row>
                <v-col>

            <h2 class="ma-10">
                Mr.Alameen (Mena Save CEO) said about us:
            </h2>
        <div class="aurora">
            <v-container>
                
            <p>
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt minima, similique quisquam sint maiores fugit, velit repellendus at dolor nostrum laudantium laborum reiciendis sequi iure. Perferendis impedit facere deleniti ullam?"
            </p>
            </v-container>
        </div>
                </v-col>
            </v-row> -->
          </v-container>
          
        <Footer></Footer>
        </div>

    </v-main>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer'
import IOsMockup from '../components/iOsMockup.vue'

export default {
    name: 'Menasave',
    components:{
        Header,
        Footer,
        IOsMockup 
    },
    
 

}
</script>
